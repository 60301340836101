import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import '../fonts/fonts-post.css';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { rhythm } from '../utils/typography';

class Tags extends React.Component {
  render() {
    const data = this.props.data;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const { tag } = this.props.pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with ${'#' + tag}`;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO />
        <div style={{ marginBottom: 'calc(1.75rem - 1px)' }}>
          <h1>{tagHeader}</h1>
          <ul>
            {edges.map(({ node }) => {
              const { slug } = node.fields;
              const { title } = node.frontmatter;
              return (
                <li key={slug}>
                  <Link to={slug}>{title}</Link>
                </li>
              );
            })}
          </ul>

          <Link to="/tags">All tags</Link>
        </div>
        <hr />
        <aside>
          <h3
            style={{
              fontFamily: 'Montserrat, sans-serif',
              marginTop: rhythm(0.25),
            }}
          >
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'var(--pink)',
              }}
              to={'/'}
            >
              pCodeLab
            </Link>
          </h3>
          <Bio />
        </aside>
      </Layout>
    );
  }
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
